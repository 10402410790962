:root {
  /* brand colors  */
  --bw-deep-blue: #003049;
  --bw-bright-yellow: #f0ff00;

  /* supporting colors */
  --bw-cream: #ffffe6;
  --bw-periwinkle-grey: #8291AC;

  /* neutrals */
  --bw-white: #fff;
  --bw-black: #000;
}

/* modes */

/* default */
:root, .default {
  --bw-primary-500: var(--bw-deep-blue);
  --bw-on-primary: var(--bw-white);
  --bw-primary-200: var(--bw-periwinkle-grey);
  --bw-secondary-500: var(--bw-bright-yellow);
  --bw-on-secondary: var(--bw-deep-blue);
  --bw-secondary-200: var(--bw-cream);

  --bw-foreground: var(--bw-primary-500);
  --bw-background: var(--bw-on-primary);
  --bw-secondary-accent: var(--bw-primary-500);
  --bw-border-soft: var(--bw-primary-200);
  --bw-text-accent: var(--bw-on-secondary);
}

.accent {
  --bw-foreground: var(--bw-on-primary);
  --bw-background: var(--bw-primary-500);
}

.secondary-accent {
  --bw-foreground: var(--bw-primary-500);
  --bw-background: var(--bw-secondary-500);
  --bw-text-accent: var(--bw-on-secondary);
}

.emphasized {
  --bw-foreground: var(--bw-on-primary);
  --bw-background: var(--bw-primary-500);
  --bw-text-accent: var(--bw-secondary-500);
  --bw-border-soft: var(--bw-primary-200);
  --bw-field-foreground: var(--bw-primary-500);
  --bw-field-background: var(--bw-on-primary);

  .accent {
    --bw-foreground: var(--bw-on-secondary);
    --bw-background: var(--bw-secondary-500);
  }
}

.emphasized-secondary {
  --bw-foreground: var(--bw-on-secondary);
  --bw-background: var(--bw-secondary-500);
  --bw-text-accent: var(--bw-primary-500);
  --bw-border-soft: var(--bw-secondary-200);
  --bw-field-foreground: var(--bw-secondary-500);
  --bw-field-background: var(--bw-on-secondary);

  .accent {
    --bw-foreground: var(--bw-on-secondary);
    --bw-background: var(--bw-secondary-200);
  }
}

.soft-primary {
  --bw-background: var(--bw-primary-200);
  --bw-foreground: var(--bw-primary-500);
  --bw-text-accent: var(--bw-secondary-500);
  --bw-border-soft: var(--bw-primary-500);
  --bw-field-foreground: var(--bw-primary-500);
  --bw-field-background: var(--bw-primary-200);

  .accent {
    --bw-foreground: var(--bw-primary-500);
    --bw-background: var(--bw-secondary-500);
  }
}

.soft-secondary {
  --bw-background: var(--bw-secondary-200);
  --bw-foreground: var(--bw-on-secondary);
}

/* TODO(khaney): remove this once we get rid of the contentful-based layout */
.new-layout {
  * {
    transition: background-color 0.075s, color 0.075s;
    border-color: var(--bw-foreground);
    color: var(--bw-foreground);
  }
}